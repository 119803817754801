import * as React from "react";
import Image, { GatsbyImageProps } from "gatsby-image";
import Styled from "styled-components/macro";
import Content from "../../../layout/Content/Content";
import MediaQuery from "../../../layout/MediaQuery";

const ImageA = Styled(Image)`
border-radius: 2rem;
  height: 70rem;
  width: 100%;
`;

const ImageB = Styled(Image)`
border-radius: 2rem;
  height: 42rem;
  width: 100%;
`;

const Container = Styled.div`
  margin: 0 0 3rem 0;
  
`;

type ComparisonScreensProps = {
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
};

export default ({ imageA, imageB }: ComparisonScreensProps) => (
  <Container>
    <Content>
      <MediaQuery lessThan="small">
        <ImageB
          fluid={"fluid" in imageB ? imageB.fluid : null}
          alt="Gallery of screens of apps and websites created in Uizard"
        />
      </MediaQuery>

      <MediaQuery greaterThan="small">
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Gallery of screens of apps and websites created in Uizard"
        />
      </MediaQuery>
    </Content>
  </Container>
);
