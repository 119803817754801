import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import Content from "../../../layout/Content/Content";
import ComparisonLandingContent from "../ComparisonLandingContent";
import ComparisonLandingQuote from "../ComparisonLandingQuote";
import MediaQuery from "../../../layout/MediaQuery";

const ImageA = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 730px;
`;

const ImageB = Styled(Image)`
    width: calc(100%);
    box-shadow: 0px 2px 20px rgba(3, 3, 3, 0.1);
    height: 365px;
`;

const Container = Styled.div`
    position: relative;
    
    svg {
        max-width: 100%;

    }
`;

const ExtendedContent = Styled(Content)`

    ${Media.lessThan("medium")`
        width: 100%;
        max-width: 100%;
    `}

`;

const WhiteOverlay = Styled.div`
    pointer-events: none;
    position: absolute;
    height: calc(50% + 5rem);
    width: calc(100% + 18rem);
    z-index: 2;
    top: 50%;
    left: -9rem;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    
    ${Media.lessThan("1024px")`
        width: 100%;
        left: 0;
    `}
`;

type ComparisonLandingProps = {
  tagline: string;
  quote: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  collabTopImageA: GatsbyImageProps;
  collabBottomImageA: GatsbyImageProps;
  collabTopImageB: GatsbyImageProps;
  collabBottomImageB: GatsbyImageProps;
};

export default ({
  imageA,
  imageB,
  tagline,
  quote,
  collabTopImageA,
  collabBottomImageA,
  collabBottomImageB,
  collabTopImageB,
}: ComparisonLandingProps) => (
  <ExtendedContent>
    <Container>
      <ComparisonLandingContent tagline={tagline} />

      <MediaQuery lessThan="900px">
        <ImageB
          fluid={"fluid" in imageB ? imageB.fluid : null}
          alt="Screenshot of the Uizard's design tool"
        />
      </MediaQuery>

      <MediaQuery greaterThan="900px">
        <ImageA
          fluid={"fluid" in imageA ? imageA.fluid : null}
          alt="Screenshot of the Uizard's design tool"
        />
      </MediaQuery>

      <WhiteOverlay />
    </Container>
    <ComparisonLandingQuote
      headline={quote}
      collabTopImageA={collabTopImageA}
      collabBottomImageA={collabBottomImageA}
      collabTopImageB={collabTopImageB}
      collabBottomImageB={collabBottomImageB}
    />
  </ExtendedContent>
);
