import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../../cards/BasicCard";

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `}
`;

export default () => (
  <Content>
    <Columns>
      <Column>
        <BasicCard
          icon="support"
          headline="For distributed teams"
          description="You can invite anyone to collaborate in real-time in Uizard regardless of
          whether they are sitting next to you in the office or working from home on the other end of the world. You can simply share a link
          as you would in Google Docs or invite your teammates by email."
        />

        <BasicCard
          icon="component"
          headline="Pre-made templates"
          description="No more blank screens! Uizard's templates always reflect your project theme and increase your flexibility while
          decreasing your work time. You can simply and easily design your project by dragging and dropping the templates you like."
        />
      </Column>

      <Column>
        <BasicCard
          icon="ai"
          dark
          headline="AI design assistant"
          description="Uizard is powered by artificial intelligence. Uizard's AI is here to assist you along your
          creative journey and to help you bring your wildest ideas to life as easily
          and quickly as possible."
        />

        <BasicCard
          icon="browser"
          headline="All in the browser"
          description="Uizard works in the web browser: no software nor plugin install required!
          It works across all platforms and all operating systems: Windows, Linux, Chrome, and Mac.
          Just open a tab and start creating!"
        />
      </Column>
    </Columns>
  </Content>
);
