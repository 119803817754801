import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import MediaQuery from "../../../layout/MediaQuery";

const TopImageA = Styled(Image)`
    position: absolute;
    top: 0;
    padding-right: 10px;
    margin-bottom: 2rem;

    ${Media.lessThan("1024px")`
        padding: 0;
    `}
`;

const BottomImageA = Styled(Image)`
    position: absolute;
    bottom: 0;
`;

const Container = Styled.div`
    position: relative;
    text-align: center;
    z-index: 3;
    transform: translateY(-11rem);

    ${Media.lessThan("900px")`
      transform: translateY(0rem);
      margin-bottom: 3rem;
    `}
`;

const Quote = Styled.p`
    font-size: 3.8rem;
    font-weight: 600;
    text-align: center;
    width: 816px;
    margin: 0 auto;
    position: relative;
    
    ${Media.lessThan("1024px")`
        max-width: 33.5rem;
        font-size: 3rem;
        line-height: 3.6rem;
    `}
    
    ${Media.lessThan("370px")`
        max-width: 27.5rem;
    `}
`;

type ComparisonLandingQuoteProps = {
  headline: string;
  collabTopImageA: GatsbyImageProps;
  collabTopImageB: GatsbyImageProps;
  collabBottomImageA: GatsbyImageProps;
  collabBottomImageB: GatsbyImageProps;
};

export default ({
  collabTopImageA,
  collabBottomImageA,
  collabTopImageB,
  collabBottomImageB,
  headline,
}: ComparisonLandingQuoteProps) => (
  <Container>
    <Quote>
      <MediaQuery lessThan="1024px">
        <TopImageA
          fluid={"fluid" in collabTopImageB ? collabTopImageB.fluid : null}
          alt="Image of people's cursors collaborating in Uizard: Developer, Business Analyst, CEO, Copywriter"
        />
        {headline}
        <BottomImageA
          fluid={
            "fluid" in collabBottomImageB ? collabBottomImageB.fluid : null
          }
          alt="Image of people's cursors collaborating in Uizard: Freelancer, Marketing Manager, Project Manager"
        />
      </MediaQuery>

      <MediaQuery greaterThan="1024px">
        <TopImageA
          fluid={"fluid" in collabTopImageA ? collabTopImageA.fluid : null}
          alt="Image of people's cursors collaborating in Uizard: Developer, Business Analyst, CEO, Copywriter"
        />
        {headline}
        <BottomImageA
          fluid={
            "fluid" in collabBottomImageA ? collabBottomImageA.fluid : null
          }
          alt="Image of people's cursors collaborating in Uizard: Freelancer, Marketing Manager, Project Manager"
        />
      </MediaQuery>
    </Quote>
  </Container>
);
