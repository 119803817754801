import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
// @ts-ignore
import ComparisonHeadline from "../../../../assets/comparison-headline-image-a.svg";
import Description from "../../../texts/Description";
import Button from "../../../buttons/Button";

const Container = Styled.div`
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 68rem;
    top: 16%;
    height: calc(100% - 16%);
    padding: 5rem 3.5rem;
    text-align: center;
    
    ${Media.lessThan("900px")`
        width: 33.5rem;
        padding: 5rem 2rem;
        
        svg {
          height: 7.8rem;
        }
    `}
`;

const ExtendedDescription = Styled(Description)`
    max-width: 46rem;
    margin: 1.5rem auto 0 auto;
    
    ${Media.lessThan("small")`
        margin: 0 auto;
    `}
`;

const ShoutoutSignupWrapper = Styled.div`
    max-width: 43rem;
    margin: 0 auto;
    margin-top: 3rem;
    
    ${Media.lessThan("900px")`
        margin-top: 1.5rem;
        max-width: 100%;
    `}
`;

const NavButtonLink = Styled.a`
  
`;

type ComparisonLandingProps = {
  tagline: string;
};

export default ({ tagline }: ComparisonLandingProps) => (
  <Container>
    <ComparisonHeadline />
    <ExtendedDescription>{tagline}</ExtendedDescription>
    <ShoutoutSignupWrapper>
      <NavButtonLink href={`https://${constants.APP_DOMAIN}/auth/sign-up`}>
        <Button solid>Sign up for free</Button>
      </NavButtonLink>
    </ShoutoutSignupWrapper>
  </Container>
);
