import * as React from "react";
import Meta from "../../components/meta";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Content from "../../components/layout/Content/Content";
import Layout from "../../components/layout/Layout";
import ComparisonLanding from "../../components/pages/uizard-vs-design-tools/ComparisonLanding";
import ComparisonFeatures from "../../components/pages/uizard-vs-design-tools/ComparisonFeatures";
import ComparisonScreens from "../../components/pages/uizard-vs-design-tools/ComparisonScreens";
import ComparisonBonus from "../../components/pages/uizard-vs-design-tools/ComparisonBonus";
import ComparisonEasy from "../../components/pages/uizard-vs-design-tools/ComparisonEasy";
import Shoutout from "../../components/pages/signup/Shoutout";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 12rem 0 10rem 0;
  `}
`;

const Comparison = ({ data }) => (
  <Layout>
    <Meta
      title="Uizard Vs Design Tools | Uizard"
      description="Not everyone is a designer, but everyone can have transformative ideas. Learn what Uizard does differently from all the rest. Read more now."
      url="/uizard-vs-design-tools/"
    />

    <Container>
      <ComparisonLanding
        tagline="No design experience nor development experience required!"
        imageA={data.comparisonLandingImageA.childImageSharp}
        imageB={data.comparisonLandingImageB.childImageSharp}
        collabTopImageA={data.comparisonLandingCollobTopImageA.childImageSharp}
        collabTopImageB={data.comparisonLandingCollobTopImageB.childImageSharp}
        collabBottomImageA={
          data.comparisonLandingCollobBottomImageA.childImageSharp
        }
        collabBottomImageB={
          data.comparisonLandingCollobBottomImageB.childImageSharp
        }
        quote="Not everyone is a designer, but everyone can
        have transformative ideas."
      />

      <ComparisonBonus />

      <ComparisonScreens
        imageA={data.comparisonScreensImageA.childImageSharp}
        imageB={data.comparisonScreensImageB.childImageSharp}
      />

      <ComparisonFeatures />

      <Content>
        <Shoutout
          headline="Design is for everyone"
          description="Create, test, collaborate, all in the browser"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Content>
    </Container>
  </Layout>
);

export default Comparison;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    comparisonLandingImageA: file(
      relativePath: { eq: "comparison/landing-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingImageB: file(
      relativePath: { eq: "comparison/landing-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingCollobTopImageA: file(
      relativePath: { eq: "comparison/landing-collab-top-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1632, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingCollobBottomImageA: file(
      relativePath: { eq: "comparison/landing-collab-bottom-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1632, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingCollobTopImageB: file(
      relativePath: { eq: "comparison/landing-collab-top-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 688, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonLandingCollobBottomImageB: file(
      relativePath: { eq: "comparison/landing-collab-bottom-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 688, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageA: file(
      relativePath: { eq: "comparison/screens-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    comparisonScreensImageB: file(
      relativePath: { eq: "comparison/screens-image-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
