import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../../cards/BasicCard";
import Headline from "../../../texts/Headline";
import Description from "../../../texts/Description";
import BasicIcon from "../../../../assets/icon-basic-card.svg";

const Icon = Styled.div`
    background-color: #E7E7E7; 
    border-radius: 1.5rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    background-color: ${(props) => props.theme.colors.gray.lighter}; 
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

const Row = Styled.div`
  width: 100%;
  
  ${Media.lessThan("600px")`
    width: 100%;
  `} 
`;

export default () => (
  <Content>
    <Columns>
      <BasicCardContainer>
        <Icon>
          <BasicIcon />
        </Icon>
        <Headline>Less is more</Headline>
        <Description>
          Uizard is not a complex photo-editing software like Adobe Photoshop
          nor a vector illustration tool like Adobe Illustrator that requires
          weeks of training before you can create anything. <br />
          <br />
          Uizard is not a complex UI design tool with a slow learning curve such
          as Sketch, Figma, Adobe XD, or InVision Studio.
          <br />
          <br />
          All these tools are made for designers.{" "}
          <b>Uizard is made for everyone else!</b>
          <br />
          <br />
          If you can use Google Slides, Keynote, or PowerPoint, you can use
          Uizard.
        </Description>
      </BasicCardContainer>
    </Columns>
  </Content>
);
